.popupbg {
  pointer-events: none;
  touch-action: none;
  background: rgba(0, 0, 0, 0.5);
  filter: brightness(0.5);
}

.carousal-banner {
  width: 100%;
}
.y {
  color: #ffc107;
}
.n {
  color: black;
}

/* ///////////// */

.click_star {
  font-size: 24px;
  cursor: pointer;
}

.click_star.checked,
.click_star:hover {
  color: #fd4;
}

/* #rate-5:checked ~ label {
  color: #fe7;
  text-shadow: 0 0 20px #952;
} */

.button-loading {
  max-width: 20px;
  max-height: 20px;
}

/* popup message */

.notification {
  position: fixed;
  bottom: 100px;
  right: 6px;
  width: max-content;
  border-radius: 6px;
  background-color: #313e2c;
  color: #82ba32;
  /* box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1); */
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  animation: fade-in 3s linear;
}

.notification_icon {
  height: 26px;
  width: 26px;
  margin-right: 4px;
}

.notification_body {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 8px;
  font-size: smaller;
  gap: 10px;
}

.notification_progress {
  position: absolute;
  left: 4px;
  bottom: 4px;
  width: calc(100% - 8px);
  height: 3px;
  transform: scaleX(0);
  transform-origin: left;
  background: linear-gradient(to right, #313e2c, #82ba32);
  border-radius: inherit;
  animation: progress 2.55s 0.35s linear;
}

@keyframes fade-in {
  5% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  95% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes progress {
  to {
    transform: scaleX(1);
  }
}

/* popup message */

.shop {
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4));
}

/* //////////////// nav visible */

.header-2.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #9d9999;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  /* background-color: #f5f2f2; */

  z-index: 1001;
}

.searchInput {
  position: fixed;
  top: 4rem;
  z-index: 1100;

  display: grid;
  justify-content: center;
  align-items: center;
}

.img-object {
  object-fit: contain;
}

.small-scale {
  transform: scale(0);
  transition: transform 0.5s ease-in-out;
}

/* .small-scale {
  transform: scale(0.8);
  transition: transform 0.2s ease;
} */

/* blog pagination */

/* Pagination styles */
.custom-pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  margin-top: 20px;
  font-size: small;
}

.custom-pagination-item {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1px;
  border: 1px solid rgb(130, 186, 50);
}

.custom-pagination-item:hover {
  background-color: #f5f5f5;
  color: black;
}

.custom-pagination-active {
  background-color: rgb(130, 186, 50);
  color: #fff;
}

.image-magnifier-container {
  max-width: 100%; /* Ensures the container resizes with the parent */
  min-height: 550px;

  display: grid;
  place-items: center;
}

.select-focused {
  position: absolute;
  min-width: 300px;
}

.flag-border {
  width: 300px;
  height: 2.5rem;
  padding-left: 8px;
  margin-right: 32px;
  border: 1px solid #9d9999;
  font-size: 15px;
  font-weight: 300;
}
@media (max-width: 768px) {
  .flag-border {
    width: 100%;
  }
}

.flag-border-checkout {
  width: 100%;
  height: 2.5rem;
  padding-left: 8px;
  margin-right: 32px;
  border: 1px solid #9d9999;
  font-size: 15px;
  font-weight: 300;
}

.PhoneInputInput {
  border: none;
  margin-left: 0.5rem;
}

/* ERROR PAGE START */
.error-page-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #1f251b;
}
.error-page-div {
  width: 100%;
  display: grid;
  place-items: center;
  gap: 20px;
}
.error-page-div img {
  width: 90px;
}

.error-page-div p {
  text-align: center;
  width: 100%;
  color: #f5f5f5;
  font-size: 22px;
  font-weight: 300;
}
/* ERROR PAGE END */
